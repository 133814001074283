require('./site/bootstrap')
import 'bootstrap/js/dist/util';

$(function () {
	const rootEl = $('html')
	const appEl = $('#app')

	/*/
	 *  Nav
	/*/
		$('[ps-nav]').click(() => {
			rootEl.toggleClass('is--nav')
		})

		$(document).keyup(function(e) {
			 if (rootEl.hasClass('is--nav') && e.keyCode == 27) {
				rootEl.removeClass('is--nav')
			}
		})


	/*/
	*  Subject
	/*/
		$('[ps-contact]').click(function () {
			let product = $(this).parent().find('h3').text()

			let setCookie = (name, value, maxAgeSeconds) => {
				let maxAgeSegment = '; max-age=' + maxAgeSeconds;
				document.cookie = encodeURI(name) + '=' + encodeURI(value) + maxAgeSegment;
			}

			setCookie('product_subject', product, 4)
		})

		const getCookie = () => {
			let subject = document.querySelector('[name=subject]')
			let cookie = 'product_subject'
			let match = document.cookie.match(new RegExp('(^| )' + cookie + '=([^;]+)'));

			if (match) {
				subject.value = 'Orçamento - ' + decodeURI(match[2])
			}
		}

		if (appEl.hasClass('contact')) {
			getCookie()
		}
		

	/*/
	*  Vídeo
	/*/
		$('[ps-video]').click(function () {
			let prefix = ''
			let params = ''
			let origin = $(this).parent().data('origin')
			let url = $(this).parent().data('url');
			let element = $(this).parent();

			if (origin === 'vimeo') {
				prefix = 'https://player.vimeo.com/video/'
				params = '?autoplay=1'
			} else {
				prefix = 'https://www.youtube.com/embed/'
				params = '?autoplay=1&color=white&modestbranding=1&rel=0&showinfo=0'
			}

			let html = '<iframe class="mr--full" frameborder="false" src="' + prefix + url + params + '" frameborder="0" allow="accelerometer; clipboard-write; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

			element.addClass('is--playing');

			setTimeout(function () {
				element.html(html);
			}, 300);
		})

		
})

/*/
 *  Alert
/*/
	window.psAlert = function (params) {
		params = {
			text: params.text ? params.text : '',
			class: params.class ? params.class : 'alert alert--info',
		}

		const alert = document.getElementById('alert')

		alert.classList = params.class
		alert.innerHTML = params.text

		setTimeout(function () { alert.classList.add('is--visible') }, 300)

		alert.addEventListener('click', function () {
			this.classList.remove('is--visible')
		})
	}

	window.psAlertDismiss = function () {
		const alert = document.getElementById('alert')
		alert.classList.remove('is--visible')
	}


/*/
 *  Forms
/*/
	window.addEventListener('load', function () {
		'use strict'
		let forms = document.querySelectorAll('.needs-validation')

		Array.prototype.slice.call(forms)
			.forEach(function (form) {
				form.addEventListener('submit', function (event) {
					if (!form.checkValidity()) {
						event.preventDefault()
						event.stopPropagation()

						let invalidFields = form.querySelectorAll('input:invalid')
						$('html, body').animate({ scrollTop: $(invalidFields[0]).offset().top - 50 }, 500)
						setTimeout(function () { invalidFields[0].focus() }, 500)
					} else {
						event.preventDefault()
						event.stopPropagation()

						psAlert({
							text: 'Enviando...',
							class: 'alert alert--info is--visible',
						})

						let data = new FormData(form)
						let fieldset = form.querySelector('fieldset')
						let button = form.querySelector('button[type=submit]')

						window.axios.post(form.action, data, {
							headers: { 'Content-Type': 'multipart/form-data' }
						}).then(res => {
							form.reset()
							fieldset.disabled = true
							button.disabled = true

							setTimeout(function () {
								psAlert({
									text: 'Dados enviados com sucesso!',
									class: 'alert alert--success is--visible',
								})
							}, 1500)

							// $('[name="' + button.dataset.form + '"] input, [name="' + button.dataset.form + '"] textarea').trigger('keydown')

							setTimeout(function () {
								psAlertDismiss()

								fieldset.disabled = false
								button.disabled = false
							}, 6000)

							form.classList.remove('was-validated')
						}).catch(error => {
							// console.log(error)

							setTimeout(function () {
								psAlert({
									text: 'Erro ao tentar enviar o formulário!',
									class: 'alert alert--error is--visible',
								})
							}, 1500)

							setTimeout(function () {
								psAlertDismiss()

								fieldset.disabled = false
								button.disabled = false
							}, 6000)
						})
					}

					form.classList.add('was-validated')
				}, false)
			})
	})